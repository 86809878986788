import React, { useState, useEffect } from 'react';
import GetCollections from '../GetCollections';
import Dropdown from '../Dropdown';
import axios from 'axios';

function CollectionTracker(props) {
  // const [accountField, setAccountField] = useState('')
  // const [account, setAccount] = useState('')
  const [contract, setContract] = useState('')
  const [collection, setCollection] = useState('')
  const [collection_id, setCollection_ID] = useState('')
  const [collectionTitle, setCollectionTitle] = useState('Select a Collection')
  const [hideDetails, setHideDetails] = useState(false)
  const [options, setOptions] = useState({ collectionViewOptions: [], collectionGroups: [], loadingoptions: 1 });
  const [filterBy, setFilterBy] = useState('All')
  const [startToken, setStartToken] = useState(0)
  const [endToken, setEndToken] = useState(0)
  const [filterOptions, setFilterOptions] = useState([
                                                      {
                                                        id: 0,
                                                        title: 'All',
                                                        selected: false,
                                                        key: 'filterOptions'   
                                                      },
                                                ])
    const [filterValue, setFilterValue] = useState('All')
    const [filterValueOptions, setFilterValueOptions] = useState([
                                                  {
                                                    id: 0,
                                                    title: 'All',
                                                    selected: false,
                                                    key: 'filterValueOptions'   
                                                  }
                                            ])
  const [sortBy, setSortBy] = useState('Price: Low to High')
  const [sortOptions] = useState([
                                                  {
                                                    id: 0,
                                                    title: 'Price: Low to High',
                                                    selected: false,
                                                    key: 'sortOptions'   
                                                  },
                                                  {
                                                    id: 1,
                                                    title: 'Rarity Rank',
                                                    selected: false,
                                                    key: 'sortOptions'    
                                                  },
                                                  {
                                                    id: 2,
                                                    title: 'Token',
                                                    selected: false,
                                                    key: 'sortOptions'    
                                                  }
                                                ])


useEffect(() => {
  const fetchData = async () => { 
    setOptions({loadingoptions: 1})   
          try {
              const collectionViewOptionsReturn = await Promise.all([axios("https://api.tokeneyes.io/api/collections_collectiontracking")]) 

              const collectionGroupsReturn = await Promise.all([axios("https://api.tokeneyes.io/api/collectionGroups")]);

              setOptions({collectionViewOptions: collectionViewOptionsReturn[0].data[0], collectionGroups: collectionGroupsReturn[0].data[0], loadingoptions: 0})
          } catch (error) {
            console.log(error)
            setOptions({loadingoptions: 2})
            }
  };
  fetchData();
},[]);

useEffect(() => {
  const fetchData = async () => { 
    // setOptions({loadingoptions: 1})   
          try {
              const attributesReturn = await Promise.all([axios("https://api.tokeneyes.io/api/collectionattributes?collection="+ collection_id)]) 
              // console.log(attributesReturn[0].data[0][0].Attributes)
              setFilterOptions(attributesReturn[0].data[0][0].Attributes)
              setFilterBy('All')
              setFilterValue('All')
              //console.log(filterOptions)
          } catch (error) {
            // console.log(error)
            // setOptions({loadingoptions: 2})
            }
  };
  if(collection_id !== '') {
    fetchData();
  }
},[collection_id]);

  // const toggleSelected = (id, key) => {
  //   let temp = JSON.parse(JSON.stringify(this.state[key]))
  //   temp[id].selected = !temp[id].selected
  //   this.setState({
  //     [key]: temp
  //   })
  // }

  const resetThenSet = (id, key) => {
    options.collectionViewOptions.forEach(item => item.selected = false);
    options.collectionViewOptions[id].selected = true;
    //seti(id)
    setContract(options.collectionViewOptions[id].contract)
    setCollection(options.collectionViewOptions[id].collectionName)
    setCollection_ID(options.collectionViewOptions[id].collectionid)
    setCollectionTitle(options.collectionViewOptions[id].title)
    // setArrayOfData(options.collectionGroups.filter(grp => {return grp.Collection_Id === options.collectionViewOptions[id].collectionid})[0].CollectionGroups)
    // let temp = JSON.parse(JSON.stringify(this.state[key]))
    // temp.forEach(item => item.selected = false);
    // temp[id].selected = true;
    // this.setState({
    //   [key]: temp,
    //   i: id,
    //   contract: temp[id].contract,
    //   collection: temp[id].collectionName,
    //   collectionTitle: temp[id].title,
    //   // filterOptions: this.state.collectionGroups[temp[id].collectionName],
    //   // filterBy: this.state.collectionGroups[temp[id].collectionName][0].title
    // })
  }

  const filterResetThenSet = (id, key) => {
    filterOptions.forEach(item => item.selected = false);
    filterOptions[id].selected = true;
    setFilterBy(filterOptions[id].title)
    setFilterValue('All')
    setFilterValueOptions(filterOptions[id].attribute_values)
  }

  const filterValueResetThenSet = (id, key) => {
    filterValueOptions.forEach(item => item.selected = false);
    const objIndex = filterValueOptions.findIndex((obj => obj.id === id));
    filterValueOptions[objIndex].selected = true;
    setFilterValue(filterValueOptions[objIndex].title)
  }

  const sortResetThenSet = (id, key) => {
    sortOptions.forEach(item => item.selected = false);
    sortOptions[id].selected = true;
    setSortBy(sortOptions[id].title)
  }
  
  const handleStartTokenChange = (event) => {
    // Update the state object
    event.preventDefault()
    setStartToken(event.target.value)
  }

  const handleEndTokenChange = (event) => {
    // Update the state object
    event.preventDefault()
    setEndToken(event.target.value)
  }

  // function getTokensClick() {
  //   setAccountField('');
  // }

  const onClick = () => {
    setHideDetails(!hideDetails);
  };

    return (   
          <div className="container-fluid mt-5">
            <div className="row">
              <div className="col-md-2 filter-bar">
                  
                  Collection
                  <Dropdown
                      title={collectionTitle}
                      list={options.collectionViewOptions}
                      resetThenSet={resetThenSet}
                  />
                  Filter Attribute
                  <Dropdown
                    title={filterBy}
                    list={filterOptions}
                    resetThenSet={filterResetThenSet}
                  />
                  Filter Attribute Value
                  <Dropdown
                    title={filterValue}
                    list={filterValueOptions}
                    resetThenSet={filterValueResetThenSet}
                  />
                  Sort By
                  <Dropdown
                    title={sortBy}
                    list={sortOptions}
                    resetThenSet={sortResetThenSet}
                  />
                  
                  Lowest Token Id
                  <div>
                  <input className ="start-token-input" type="text" placeholder='0' onChange={handleStartTokenChange} />
                  </div>
                  Highest Token Id
                  <div>
                  <input className ="start-token-input" type="text" placeholder='0' onChange={handleEndTokenChange} />
                  </div>
                <div>
                  <input
                    className=""
                    type="checkbox"
                    value={"this.state.hideDetails"}
                    checked={hideDetails}
                    onChange={onClick}
                  /> Hide Details
                </div>
                {/* <button className="button-te" onClick={(event) => {
                  event.preventDefault()
                  this.getTokensClick();
                }} id="login"> Get Tokens </button>  */}
              
              </div>
              <div className="col-md-10">
                <div className="row">
                                
                  <div className="col-md-12 text-center">
                  
                    <GetCollections /*account={account}*/ collectionTitle={collectionTitle} contract={contract} collectionID={collection_id} 
                    collection={collection} filterBy={filterBy} filterValue={filterValue} sortBy={sortBy} hideDetails={hideDetails}
                    collections={options.collectionViewOptions} collectionGroups={options.collectionGroups} loadingoptions={options.loadingoptions}
                    startToken={startToken} endToken={endToken}
                    />
                  </div>
                </div>
              </div>
              
            </div> 
            <div className="row">
                                
                  <div className="col-md-12 text-center">
            <p className="disclaimer">Disclaimer: OpenSea links are tagged with a referral link to the TokenEyesio wallet address. OpenSea is not currently supporting this referral program. Please consider donating to support TokenEyes. Thank you for your support!
        </p>
        </div>
              
            </div>
       
          </div>
          
        
    );

}

export default CollectionTracker;