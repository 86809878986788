import React from 'react';

const Home = () => {
    return (
        <div className="container-fluid mt-5">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h5>Welcome to TokenEyes!</h5>
                    <p className="text-center summary">
                        <br/>TokenEyes is a project of love designed to give a single pane to view all attributes of owned tokens.<br/>
                        <br/>
                        <br/>Currently supported projects include:
                        <br/>
                        <a className="project-link" target="_blank" href={"https://www.dysto.inc/"} rel="noopener noreferrer">
                          Dysto Apez
                        </a>
                        <br/>
                        <a className="project-link" target="_blank" href={"https://www.nicefunzombies.io/"} rel="noopener noreferrer">
                          Nice Fun Zombies
                        </a>
                        <br/>
                        <a className="project-link" target="_blank" href={"https://uunicorns.io/"} rel="noopener noreferrer">
                          Uninterested Unicorns
                        </a>
                        <br/>
                        <br/>
                        Use the TokenTracker to see all of your tokens and attributes in one easy place. 
                        <br/>
                        Use the CollectionTracker to see all tokens from a specific collection and filter by attributes.
                        <br/>
                        <br/>Further collections and functionality are planned to be added as resources allow.
                        <br/>  
                    </p>
                </div>
            </div>          
        </div>
        
    );
};

export default Home;