import Moralis from 'moralis';
import React from 'react';
import { useMoralis } from "react-moralis";
import '../App.css';




function Authenticate(props) {
    const { authenticate, isAuthenticated, isAuthenticating, user, provider, /*account, enableWeb3, isWeb3Enabled,*/ logout } = useMoralis();
    var currentUser = ''   
   
    if (isAuthenticated) {
        currentUser = user.get('ethAddress')
        // console.log(user.ethAddress)
        // setState({user: user.id});
        props.getUser(user.get('ethAddress'))
    }

    const metamaskLogin = async () => {
      if (!isAuthenticated) {

        await authenticate({signingMessage: "Log in using Moralis"})
          .then(function (user) {
            console.log("logged in user:", user);
            currentUser = user.id
            // console.log(user.id)
            // setState({user: user.id});
            // props.getUser(user.id)
            // console.log(user!.get("ethAddress"));
          })
          .catch(function (error) {
            // console.log(error);
          });
      }
    }

    async function walletconnectLogin() {
        const user = authenticate({
          provider: "walletconnect",
          chainId: 56,
          mobileLinks: [
            "metamask",
            "trust"
          //   "rainbow",
          //   "argent",
          //   "imtoken",
          //   "pillar",
          ],
          signingMessage: "Welcome!",
        });
        // console.log(user);
      }
      async function processTX() {
        
        const provider = await Moralis.enableWeb3();
        const ethers = Moralis.web3Library;
        const ethAddress = user.get('ethAddress');
        let signer = provider.getSigner(ethAddress);
        //const provider = Moralis.getWeb3Provider
        const contractAddress = "0x011C77fa577c500dEeDaD364b8af9e8540b808C0";
        const abi = [
          {
            inputs: [
              {
                "name": "_ipcId",
                "type": "uint"
              },
              {
                "name": "_newPrice",
                "type": "uint"
              }
            ],
            name: "buyIpc",
            outputs: [],
            payable: true,
            stateMutability: "payable",
            type: "function",
          },
        ] 
      const contract = new ethers.Contract(contractAddress, abi, signer )
      console.log(contract)
      contract.buyIpc("304","100000000", {value: ethers.utils.parseEther("0.455"), gasLimit: 200000})
      /*
        let options = {
          contractAddress: "0x011C77fa577c500dEeDaD364b8af9e8540b808C0",
          functionName: "buyIpc",
          abi: [
            {
              inputs: [
                {
                  "name": "_ipcId",
                  "type": "uint"
                },
                {
                  "name": "_newPrice",
                  "type": "uint"
                }
              ],
              name: "buyIpc",
              outputs: [],
              payable: true,
              stateMutability: "payable",
              type: "function",
            },
          ],
          params: {
            _ipcId: 731,
            _newPrice: 10000000
          },
          msgValue: Moralis.Units.ETH(0.1),
        };*/
       // await Moralis.enableWeb3();
        //await Moralis.executeFunction(options);
        // console.log("logged out");
      }
    // useEffect(() => {
    //     if (!isWeb3Enabled && isAuthenticated) {
    //       enableWeb3({ provider: "walletconnect", chainId: 56 });
    //       console.log("web3 activated");
    //     }
    //   }, [isWeb3Enabled, isAuthenticated, enableWeb3]);

    const logOut = async () => {
      await logout();
      console.log("logged out");
      currentUser = ''
    //   setState({user: ''});
      // props.getUser('')
    }

  return (
    <div>
      {isAuthenticated ?       
        <div>
            {currentUser}
            <button className="button-te" onClick={processTX}>Process</button>
            <button className="button-te" onClick={logOut} disabled={isAuthenticating}>Logout</button>
        </div>
        :
        
        <div>
            <button className="button-te" onClick={metamaskLogin}>Metamask Login</button>
            <button className="button-te" onClick={walletconnectLogin}>WalletConnect Login</button>
        </div>
        }
     </div>
  );
}

export default Authenticate;