import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './App.css';
import TokenEyes from './TokenEyes';
import TokenTracker from './TokenTracker';
import CollectionTracker from './CollectionTracker';
import AdSense from 'react-adsense';
import Authenticate from './Authenticate';
//import MyCollection from './MyCollection';

class App extends Component {
  
  constructor(props) {
    // var user = ''
    super(props)
    this.setLoggedInUser.bind(this)
    this.state = {
      teAddress: '0xCf9160Cb6bc241Bdc1cd4Ba958c9b2114172F734',
      copySuccess: '0xCf9160Cb6bc241Bdc1cd4Ba958c9b2114172F734',
    }
  }


  async componentDidMount() {
    //await this.loadWeb3();
    //await this.loadBlockChainData();
  }

  async loadWeb3() {
    if (window.ethereum) {
      // window.web3 = new Web3(window.ethereum);
      // await window.ethereum.enable();
      // try {
      //     const accounts = await window.ethereum.request({ method: 'eth_requestAccounts'});
      //     this.state.account = accounts[0];
      // } catch (error) {
      //   if (error.code === 4001) {
      //     window.alert('Please manually entry your address.')
      //   }
      // }
      // let web3 = new Web3(Web3.givenProvider || "ws://localhost:8545")
      // console.log(web3.givenProvider)
    }else {
      window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!')
    }
    
  }
  
  setLoggedInUser = (newUser) => {
    this.user = newUser
     console.log('User ' + this.user)
  }

  copyTEAddress = async () => {
    await navigator.clipboard.writeText(this.state.teAddress);
    this.setState({copySuccess: 'Copied!'});
    // this.setState({copySuccess: this.state.teAddress});
  }
  
  render() {
    return (
      <div className="App">
        <Navbar expand="md" fixed="top" className="tenavbar">
          <Container>
            
            <Navbar.Brand href="/home">TokenEyes</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <div className="col-8">
              <Nav className="me-auto">
                <Nav.Link href="/home">Home</Nav.Link>
                <Nav.Link href="/tokentracker">TokenTracker</Nav.Link>
                <Nav.Link href="/collectiontracker">CollectionTracker</Nav.Link>
                {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                </NavDropdown> */}
              </Nav>
            </div>
            {/* <div className="col-4">
              <Authenticate getUser={this.setLoggedInUser}/>
            </div> */}
            </Navbar.Collapse>
          </Container>
        </Navbar>          
        <header>
          <Router>
            <Routes>
              <Route exact path="/" element={<TokenEyes/>}/>
              <Route path="/Home" element={<TokenEyes/>}/>
              <Route path="/TokenTracker" element={<TokenTracker loggedInUser={this.user}/>}/>
              <Route path="/CollectionTracker" element={<CollectionTracker/>}/>
              {/* <Route path="/MyCollection" element={<MyCollection/>}/> */}
              <Route path="*" element={<TokenEyes/>}/>
            </Routes>
          </Router> 
          <AdSense.Google
            client='ca-pub-3295563998514580'
            slot='5427398918'
          />
        </header>
        <footer className="fixed-bottom footer text-center">
          <p>If the tool has been helpful for you, please consider supporting the project via tips/donations in ETH or WETH (Polygon) to:</p>
            <div className="address-copy-div" >
              <button className="address-copy" onClick={this.copyTEAddress}> <span className="address-copy-span"> {this.state.copySuccess} </span> </button>   
            </div>         
          <p> &copy; TokenEyes.io {new Date().getFullYear()}</p>
        </footer>
      </div>
    );
  }
}

export default App;
