import React, {useState, useEffect} from 'react';
import axios from 'axios';
// import LazyImage from '../../functions/LazyImage.js';
// import CollectionLazyToken from '../../functions/CollectionLazyToken.js';
// import InfiniteScroll from 'react-infinite-scroll-component';
import InfiniteScrollTokens from '../InfiniteScrollTokens/InfiniteScrollTokens';
import '../App.css';
const BigNumber = require('bignumber.js');

function GetCollections(props) {

    // const abis = require('../../abis/TokenABIs.json')
    // const abi = abis['ABI_1']
    // const contractAddress = props.contract
    // var contract = null;
   // const [collectionSummary, setCollectionSummary] = useState({ collectionsummary: [] });
    const [tokenData, setTokenData] = useState({ tokendata: [], collectionsummary: [], loadingdata: 0 });
    //const [downloadLink, setDownloadLink] = useState('')
    // const collectionGroups = require('../../lists/collectiongroups.json')
    // const collections = require('../../lists/collections.json')
    // const collectionDetails = collections[props.collectionID]
    // const sortList = collectionGroups[props.collection]
    // const grp1 = sortList[0].title
    // const grp2 = sortList[1].title
    
    var collectionDetails = []

    var sortList = []
    var grp1 = ''
    var grp2 = ''

    if(props.loadingoptions === 0 & props.collection !== '') {
      collectionDetails = props.collections.filter(col => {return col.collectionid === props.collectionID})[0]
       sortList = props.collectionGroups.filter(grp => {return grp.CollectionName === props.collection})[0].CollectionGroups
     grp1 = sortList[0].title
     grp2 = sortList[1].title
    }

    //var filter = ""
    // const [collectionCheck, setCollectionCheck] = useState({collectioncheck: ""})
    //if (props.sortBy === "Questing Party") {sort = "Clan"} else {sort = props.sortBy};
    // const Web3 = require('web3')
    //var loadingdata = 0
    // var current = 0
    // var total = 0
    
useEffect(() => {
    const fetchData = async () => {
        // const provider = 'https://mainnet.infura.io/v3/199652b7f3ab4326b7833e0a7ef765f7'
        // const abis = require('../../abis/TokenABIs.json')
        // const abi = abis['ABI_1']
        // const web3 = new Web3(new Web3.providers.HttpProvider(provider))
        const networkId = 1
        // var uris = []
        var tdata = []
        
        
        // const threadLimit = 50;Y
        // const rateLimit = 1000; // ms
        // let activeThreads = 0;
        //console.log(tokenData.tokendata[0].length)
        // if(networkId === 1 && tokenData.tokendata.length === 0){
        if(networkId === 1 & props.collectionID !== ''){
          setTokenData({loadingdata: 1})  
          async function getFromTokenEyes() {        
            try {
                const collSummary = await Promise.all([axios("https://api.tokeneyes.io/api/collection/summary?collection="+ props.collectionID +"&summaryatt1="+ grp1 +"&summaryatt2="+ grp2)]) 

                const tokenData = await Promise.all([axios("https://api.tokeneyes.io/api/collection/tokens?collection="+props.collection)]);

                tdata.push([tokenData]);
              //console.log(tokenData)
                const tokenObj = []
                  tokenObj.push(tokenData[0].data.map(t => ({
                  Token: t.Token,
                  TokenName: t.TokenName,
                  TokenDescription: t.TokenDescription,
                  TokenImage: t.TokenImage.replace('ipfs://', 'https://ipfs.io/ipfs/'),
                  RarityRank: t.RarityRank,
                  LowestListing:  new BigNumber(t.LowestListing).shiftedBy(-18),
                  ListingSort:  new BigNumber(t.LowestListing).shiftedBy(-18).toNumber(),
                  attributes: t.attributes.map((a) => ({
                                            attribute_name: a.attribute_name,
                                            attribute_value: a.attribute_value
                                          }))
                                })),
                  
                )
                setTokenData({tokendata: tokenObj[0], collectionsummary: collSummary[0], loadingdata: 0})
            } catch (error) {
              setTokenData({loadingdata: 2})
              }
            //  setCollectionSummary({collectionsummary: collSummary[0]})   
          }  
          
        //   contract =  await new web3.eth.Contract(abi, '0x65273D9be210e10be64D7E122387cc85857F92FD')
            
        //     // get owned tokens owned by address
        //      const balance = await contract.methods.totalSupply().call()
        //     // total = balance
        //     // const balance = 10
        //     // var ownedTokens = []
          
        //     for (var i = 1; i <= balance; i++){
        //         uris.push([ i, "https://api.nicefunzombies.io/metadata/"+i ])
        //     }    
             
        //     // await Promise.all(uris.map(async (tokenURI) => {
        //     //   const tokenData = await Promise.all([axios(tokenURI[1])]); 
        //     //     tdata.push([tokenURI[0], tokenData])
        //     //  }));  
        //     const interval = setInterval(run, rateLimit);
        //     async function run() {
        //       if (uris.length === 0) {
        //         console.log('complete');
        //         clearInterval(interval);
        //         loadingdata = 0;
        //         const tokenObj = []
        //       tokenObj.push(tdata.map((v,k) => ({
        //       tokenId: v[0],
        //       tokenImg: v[1][0].data.image,
        //       tokenName: v[1][0].data.name,
        //       tokenDescription: v[1][0].data.description,
        //       attributes: v[1][0].data.attributes.map((a) => ({
        //                                                         attribute_type: a.trait_type,
        //                                                         attribute_value: a.value
        //                                                       }))
        //                             })),
              
        //      )
        //      setTokenData({tokendata: tokenObj})     
             
        //      //****Use below to save to CSV ****//
        //      const tokens = []
        //      tokenObj[0].map(e => { e.attributes.map(a => {
        //         tokens.push([e.tokenId, e.tokenName, e.tokenDescription, a.attribute_type, a.attribute_value])
        //       })
        //      })
        //     // console.log(test)
        //     const csvContent = "tokenId,tokenName,tokenDescription,attributeName,attributeValue\n"+ tokens.map(e => e.join(",")).join("\n");
 
        //     //console.log(csvContent)
        //     const data = new Blob([csvContent], { type: 'text/csv' })
        //     // this part avoids memory leaks
        //     if (downloadLink !== '') window.URL.revokeObjectURL(downloadLink)

        //     // update the download link state
        //     setDownloadLink(window.URL.createObjectURL(data))
        //     //  console.log(tokenObj);
                
        //         return;
        //       }
            
        //       // threadLimit - activeThreads is how many new threads we can start
        //       for (let i = 0; i < threadLimit - activeThreads && uris.length > 0; i++) {
        //         // console.log(uris.length);
        //         activeThreads++; // add a thread
        //         const toProcess = uris.shift();
        //         call(toProcess)
        //           .then(done(toProcess));
        //       }
              
        //     }
            
        //     function done(val) {
        //       console.log(`Done ${val[0]}`);
        //     //   current = val[0];
        //       activeThreads--; // remove a thread
        //     }
            
        //     function call(val) {
        //       console.log(`Starting ${val[0]}`);
        //       return new Promise(resolve => waitToFinish(resolve, val));
        //     } 
             
        //     async function waitToFinish(resolve, val) {
        //         // console.log(`Made it to waitToFinish for ${val[0]}`);
        //         const tokenData = await Promise.all([axios(val[1])]);
        //         //console.log(tokenData);
        //         // const done = 1 > 0;
        //         tdata.push([val[0], tokenData]);
        //         //console.log(done);
        //         return done;
        //     } 
             
        // if (uris.length > 0) {
        //        await run();
        //       }
        //     }
        // loadingdata = 0;
        getFromTokenEyes();
        }
    };
    fetchData();
},[props.collection]); 

//Token Sort by ID Function    
function GetSortOrder(sort) {     
  return function(a, b) {   
      if (sort === "Price: Low to High") {
        let aprice, bprice
        if (a.LowestListing.toString() === "NaN") { aprice = 99999999} else { aprice = a.LowestListing.toNumber()}
        if (b.LowestListing.toString() === "NaN") { bprice = 99999999} else { bprice = b.LowestListing.toNumber()}
       
        if (aprice > bprice) return 1;
          if (aprice < bprice) return -1;
            if (a.tokenId > b.tokenId) return 1;
              if (a.tokenId < b.tokenId) return -1;
                else return 0;   
      } else if (sort === "Rarity Rank") {   
        if (parseFloat(a.RarityRank) > parseFloat(b.RarityRank)) return 1;
          if (parseFloat(a.RarityRank) < parseFloat(b.RarityRank)) return -1;
            if (a.tokenId > b.tokenId) return 1;
              if (a.tokenId < b.tokenId) return -1;
                else return 0;   
                      }
          else {   
            if (a.tokenId > b.tokenId) return 1;
              if (a.tokenId < b.tokenId) return -1;
                else return 0;   
                          }    
  }      
}

// useEffect(() => {
//   filteredTokens.sort(GetSortOrder(props.sortBy))
// },[props.sortBy]); 
 // console.log(tokenData.tokendata)                       
//  Return the markup.
var foundTokens

if(tokenData.tokendata) {
  if(tokenData.tokendata.length){
    foundTokens = true
  } else {foundTokens = false}
} else {foundTokens = false}

// console.log(foundTokens)
if (foundTokens) {  
  var filteredTokens = []
  
    //console.log(collectionDetails)
      // Filter the token list by selected attribute.
       if (props.filterBy === "All") {
         filteredTokens = tokenData.tokendata
       } else {
         filteredTokens = tokenData.tokendata.filter(t => t.attributes.filter(x => x.attribute_name === props.filterBy).some(a => a.attribute_value === props.filterValue ))
       }
    
      // setDisplayTokens({displayTokens: filteredTokens.sort(GetSortOrder(props.sortBy))})
      const grpAtt1Array = Object.entries(tokenData.collectionsummary.data[0]);
      const grpAtt2Array = Object.entries(tokenData.collectionsummary.data[1]);
      
      //  Sort the tokens by selected sort option.
      // filteredTokens = filteredTokens.sort(GetSortOrder(props.sortBy))
      var returnTokens = [...filteredTokens].sort(GetSortOrder(props.sortBy))

      if(parseInt(props.startToken) != 0 || parseInt(props.endToken) != 0){
        if(parseInt(props.startToken) <= parseInt(props.endToken)){
          returnTokens = returnTokens.filter(t => parseInt(t.Token) >= parseInt(props.startToken))
        }
        if(parseInt(props.endToken) >= parseInt(props.startToken)){
          returnTokens = returnTokens.filter(t => parseInt(t.Token) <= parseInt(props.endToken))
        }
          // console.log(returnTokens[0].Token, props.startToken, props.endToken)
      }

      //console.log(props.sortBy)
      // console.log(filteredTokens)
      //  Return the markup.
      return (
       
          
          <div className="container-fluid">
            <div className="row">
            <div className="col-md-11">
            {props.collectionTitle} 
            <br/>Note: OpenSea listing functionality is available but is in testing. Please verify prior to purchasing.
            {/* <br></br>
            <a
              // this attribute sets the filename
              download='tokens.csv'
              // link to the download URL
              href={downloadLink}
            >
              Download Token Data csv
            </a> */}
             {/* owned by {props.sortBy}          */}
             <div className="row text-center token-wrapper">
            <div className="col-md-3 bg-token">
            <div className="token-name ">{grp1} Summary</div>
              <table className="table-group-summary">
              <thead>
                  <tr>
                    <th></th>
                    <th>Quantity</th>
                    <th>Floor</th>
                  </tr>
                </thead>
                <tbody>
                {grpAtt1Array.map((a) => {return ( <tr key={a[0]}> 
                                                                              <td className="att-title-sum"> <span> {a[1].Attribute}: </span> </td> 
                                                                              <td className="att-val-sum"> <span> {a[1].Quantity} </span> </td>
                                                                              <td className="att-val-sum"> <span> {a[1].LowestListing === null ? "-" : new BigNumber(a[1].LowestListing).shiftedBy(-18).toString()}</span> </td> 
                                                                              </tr>)}
                                                                              )} 
                </tbody>
              </table>
            </div>
            <div className="col-md-3 bg-token">
            <div className="token-name">{grp2} Summary</div>
              <table className="table-group-summary">
              <thead>
                  <tr>
                    <th></th>
                    <th>Quantity</th>
                    <th>Floor</th>
                  </tr>
                </thead>
                <tbody>
                {grpAtt2Array.map((a) => {return ( <tr key={a[0]}> 
                                                                              <td className="att-title-sum"> <span> {a[1].Attribute}: </span> </td> 
                                                                              <td className="att-val-sum"> <span> {a[1].Quantity} </span> </td>
                                                                              <td className="att-val-sum"> <span> {a[1].LowestListing === null ? "-" : new BigNumber(a[1].LowestListing).shiftedBy(-18).toString()}</span> </td> 
                                                                              </tr>)}
                                                                              )} 
                </tbody>
              </table>
              </div>
            </div>
            
            {/* <InfiniteScroll
              dataLength={current.length}
              next={getMoreData}
              hasMore={hasMore}
              loader={<h4>Loading...</h4>}
            > */}
            
            <div className="row text-center token-wrapper no-xscroll">             
            <InfiniteScrollTokens filteredTokens={returnTokens} props={props} collectionDetails={collectionDetails}/>             
              </div>
              {/* </InfiniteScroll> */}
              </div>
              {/* <div className="col-md-1">
                <div className="col-md-12 text-center suggestions">
                    <div className="token-name">Suggested Additions</div>
                       <GetSuggested contract={props.contract} neededclan={neededClan}/>  
                </div>
              </div> */} 
        
              </div>
        
            </div>
        
      );
    } else {
        return(
          
            <div className="tile">
            <div className="col-md-11">
            {tokenData.loadingdata === 1 ?
            
                      // <div class="col-12">
                  <div className="snippet" data-title=".dot-falling">
                    <div className="stage">
                    <p> Fetching the collection's tokens. Please be patient, this may take a few minutes.</p>
                      <div className="dot-falling"></div>
                    </div>
                  </div>
                // </div>
            : tokenData.loadingdata === 2 ? <p>There was a problem loading data from the TokenEyes API. Please try back later.</p> 
            : <p>Please select a Collection to retrieve a list of tokens.</p>
            }                 
            </div>
            </div>
        )
    }
  }

export default GetCollections;