import React from 'react';
import ReactDOM from 'react-dom';
import './components/App.css'
import 'bootstrap/dist/css/bootstrap.css'
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { MoralisProvider } from "react-moralis";

ReactDOM.render(
    <React.StrictMode>
        <MoralisProvider serverUrl="https://ggfzvpelu0ap.usemoralis.com:2053/server" appId="NFeZh3HaRF90CntIEApWkzLAiRi9Wv9wSMfmOprF">
            <App />
        </MoralisProvider>
    </React.StrictMode>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
