import React, {useState, useEffect} from 'react';
import axios from 'axios';
import OwnedLazyToken from '../../functions/OwnedLazyToken.js';
// import Web3 from 'web3';
import '../App.css';
//import GetSuggested from '../GetSuggested';
import { useMoralis } from "react-moralis";

function GetTokens(props) {
    const abis = require('../../abis/TokenABIs.json')
    const abi = abis['ABI_1']
    const contractAddress = props.contract
    var contract = null;
    const [tokenData, setTokenData] = useState({ tokendata: [], loadingdata: 0 });
    //const collectionGroups = require('../../lists/collectiongroups.json')
    //const collections = require('../../lists/collections.json')
    var collectionDetails = []

    var sortList = []
    var grp1 = ''
    var grp2 = ''

    if(props.loadingoptions === 0) {
      collectionDetails = props.collections.filter(col => {return col.collectionid === props.collectionID})[0]
       sortList = props.collectionGroups.filter(grp => {return grp.CollectionName === props.collection})[0].CollectionGroups
     grp1 = sortList[0].title
     grp2 = sortList[1].title
    }


    // const sortList = props.collectionGroups.filter(grp => {return grp.CollectionName === props.collection})[0]
    // const grp1 = sortList[0].title
    // const grp2 = sortList[1].title
    var sort = ""
    const [collectionCheck, setCollectionCheck] = useState({collectioncheck: "Select a Collection"})
    if (props.sortBy === "Questing Party") {sort = "Clan"} else {sort = props.sortBy};
    const Web3 = require('web3')
    // const [neededClan, setNeededClan] = useState('')
    const { Moralis } = useMoralis()

    useEffect(() => {
    const fetchData = async () => {
        const provider = 'https://mainnet.infura.io/v3/199652b7f3ab4326b7833e0a7ef765f7'
        
        // const resAcct = await Promise.all([ axios("http://localhost:3001/post")])
        //const provider = new Web3.providers.HttpProvider(window.ethereum)
        //window.web3 = new Web3(window.ethereum)
      
        const web3 = new Web3(new Web3.providers.HttpProvider(provider))
        const networkId = 1
        var uris = []
        var tdata = [] 
        
        if(props.account !== null & props.account !== ''){
        var addr = props.account
        let res = web3.utils.isAddress(addr)
          if(!res){          
            try {
              addr =  await web3.eth.ens.getAddress(addr)
            } catch (err){
              addr = ''
            }  
          }
        }

        if(networkId === 1 & props.account !== null & props.account !== '' & /*addr !== '' &*/ contractAddress !== '' & props.collection !== collectionCheck.collectioncheck ){
          setTokenData({loadingdata: 1})
          try {
        //     const apiReturn = await Promise.all([axios("https://api.tokeneyes.io/api/user/nfts?contractAddress="+contractAddress+"&hasIEnumerable="+props.hasIEnumberable+"&userAddress="+props.account)])
        //  console.log(apiReturn)
            var ownedTokens = []
            contract =  await new web3.eth.Contract(abi, contractAddress)
            if(props.hasIEnumberable === 1) {
                
                // get owned tokens owned by address
                const balance = await contract.methods.balanceOf(addr).call()
              
                for (var i = 1; i <= balance; i++){
                    const ownedToken = await contract.methods.tokenOfOwnerByIndex(addr, i - 1).call()  
                    ownedTokens.push(ownedToken)
                }    
            
                await Promise.all(ownedTokens.map(async (token) => {
                const tokenURI = await contract.methods.tokenURI(token).call() 
                uris.push([ parseInt(token), tokenURI])           
                }));
              } else {
                const ethNFTs = await Moralis.Web3API.account.getNFTsForContract({chain: 'eth', address: addr, token_address: contractAddress})

                for (var ii = 1; ii <= ethNFTs.result.length; ii++){ 
                  ownedTokens.push(ethNFTs.result[ii-1].token_id)
                }

                // console.log(ethNFTs)
                // console.log(ownedTokens)
                await Promise.all(ownedTokens.map(async (token) => {
                  const tokenURI = await contract.methods.tokenURI(token).call() 
                  uris.push([ parseInt(token), tokenURI.replace('ipfs://', 'https://ipfs.io/ipfs/')])           
                  }));
              }

            await Promise.all(uris.map(async (tokenURI) => {
              // const tokenData = await Promise.all([axios(tokenURI[1])]); 
              const tokenData = await Promise.all([axios("https://api.tokeneyes.io/tokenapi?url="+tokenURI[1])]); 
                tdata.push([tokenURI[0], tokenData])
             }));  
             
             const tokenObj = []
              tokenObj.push(tdata.map((v,k) => ({
              tokenId: v[0],
              tokenImg: v[1][0].data.image.replace('ipfs://', 'https://ipfs.io/ipfs/'),
              tokenName: v[1][0].data.name,
              tokenDescription: v[1][0].data.description,
              attributes: v[1][0].data.attributes.map((a) => ({
                                                                attribute_type: a.trait_type,
                                                                attribute_value: a.value
                                                              }))
                                    })),
              
             )
             
             // Push the found data fields we need into our token data array for sorting and display.
             setTokenData({tokendata: tokenObj, loadingdata: 0})
             setCollectionCheck({collectioncheck: props.collection})
            } catch (error) {
              console.log(error)
              setTokenData({loadingdata: 2})
              }
            
        } 
    };
    fetchData();
},[props]); 
 
//Quest Group Function
function GetQuestingGroups(tokens) {
  var tokensToGroup = tokens.slice()
  tokensToGroup.sort(GetSortOrderForQuesting("Clan"))
  //Get a list of clans to iterate over and reduce
  var clanArr =  tokens.map(t => t.attributes.filter(x => x.attribute_type === "Clan")[0].attribute_value)
  const clanObj = {}
  for (const clan of clanArr) {
    clanObj[clan] = (clanObj[clan] || 0) + 1;
  }
  
  //let clanlist = ["Dark", "Air", "Earth", "Fire", "Water", "Pure"]
  let questGroups = {}
  let grps = Math.ceil(tokensToGroup.length/5)
  let clantofill = 0
  let clantofind = null

  //create the object with enough groups to hold all tokens with max 5 per group
  for (let y = 0; y < grps; y++) {
    questGroups[y] = []
  }

  //populate each array in the token object with its tokens
  for (let i = tokensToGroup.length - 1; i >= 0;) {
     
    if (clantofind === null || clantofill === 0) {
      clantofind = Object.keys(clanObj).reduce((a, b) => clanObj[a] > clanObj[b] ? a : b)
      clantofill = clanObj[clantofind] > 5 ? 5 : clanObj[clantofind]
      
    } 

    let clan = tokensToGroup[i].attributes.filter(x => x.attribute_type === "Clan")[0].attribute_value

    if (clan === clantofind) {
      for (let g = 0; g < grps;) {
        
        var qg
        let qglen = questGroups[g].length
        // let remaining = 5 - qglen
        // let currclan = questGroups[g].filter(t => t.attributes.filter(x => x.attribute_type === "Clan")[0].attribute_value === clan).length
        // let totalclan = tokens.filter(t => t.attributes.filter(x => x.attribute_type === "Clan")[0].attribute_value === clan).length

        if (qglen < 5 )
        { if (clantofill <= 5 - qglen) {
            qg = g
          
            questGroups[qg].push(tokensToGroup[i])
            tokensToGroup.splice(i, 1)
            clanObj[clantofind] = clanObj[clantofind] - 1
            clantofill = clantofill - 1
            if (i === 0) {
              i = tokensToGroup.length - 1
            } else {
              i = i - 1
            }
            g = grps
          } else if (g === grps) {
              qg = g
            
              questGroups[qg].push(tokensToGroup[i])
              tokensToGroup.splice(i, 1)
              clanObj[clantofind] = clanObj[clantofind] - 1
              clantofill = clantofill - 1
              if (i === 0) {
                i = tokensToGroup.length - 1
              } else {
                i = i - 1
              }
              g = grps
            }
            else {
            g = g + 1
            }
        } else {
           g = g + 1
        }
      }
 
    } else {
     if (i === 0) {
      i = tokensToGroup.length - 1
     } else {
      i = i - 1
     }
    }
  }



  
  const questGroupArray = Object.entries(questGroups);

  return questGroupArray
}

function GetGroupSummary(questGroups) {
  let clanlist = ["Dark", "Air", "Earth", "Fire", "Water", "Pure"]
  let groupSummary = {}
  
  //console.log(questGroups)
  //create the object with the a groups to hold the summary for eachgroup
  for (let y = 0; y < questGroups.length; y++) {
    groupSummary[y] = {
      Dark: 0,
      Pure: 0,
      Air: 0,
      Earth: 0,
      Fire: 0,
      Water: 0,
      ClanBonus: 0,
      Rares: 0,
      RarityBonus: 0,
      TotalBonus: 0,
      TotalGain: 0
    }
  }

  questGroups.map(g => g[1].map(function(t) { 
    var clan = t.attributes.filter(a => a.attribute_type === "Clan")[0].attribute_value
    var clss = t.attributes.filter(a => a.attribute_type === "Class")[0].attribute_value

    groupSummary[g[0]][clan] = groupSummary[g[0]][clan] + 1
    if (clss === "Punk" || clss === "Zombie" || clss === "Golden" || clss === "Uni Mech") {
      groupSummary[g[0]]['Rares'] = groupSummary[g[0]]['Rares'] + 1
    }
    return 0
  }))
 
Object.keys(groupSummary).map(function(key){
  for (let c = 0; c < clanlist.length; c++) {
    var clan = clanlist[c]
    //Calculate Clan Bonus
    switch (groupSummary[key][clan]) {
      case 2: groupSummary[key]['ClanBonus'] = groupSummary[key]['ClanBonus'] + 2;
      break;
      case 3: groupSummary[key]['ClanBonus'] = groupSummary[key]['ClanBonus'] + 4;
      break;
      case 4: groupSummary[key]['ClanBonus'] = groupSummary[key]['ClanBonus'] + 6;
      break;
      case 5: groupSummary[key]['ClanBonus'] = groupSummary[key]['ClanBonus'] + 10;
      break;
      default: groupSummary[key]['ClanBonus'] = groupSummary[key]['ClanBonus'] + 0;
    } 
  }

  //Calculate Rarity Bonus
  switch (groupSummary[key]['Rares']) {
    case 1: groupSummary[key]['RarityBonus'] = groupSummary[key]['RarityBonus'] + 2;
    break;
    case 2: groupSummary[key]['RarityBonus'] = groupSummary[key]['RarityBonus'] + 4;
    break;
    case 3: groupSummary[key]['RarityBonus'] = groupSummary[key]['RarityBonus'] + 6;
    break;
    case 4: groupSummary[key]['RarityBonus'] = groupSummary[key]['RarityBonus'] + 8;
    break;
    case 5: groupSummary[key]['RarityBonus'] = groupSummary[key]['RarityBonus'] + 10;
    break;
    default: groupSummary[key]['RarityBonus'] = groupSummary[key]['RarityBonus'] + 0;
  }
  groupSummary[key]['TotalBonus'] = groupSummary[key]['ClanBonus'] + groupSummary[key]['RarityBonus']
  groupSummary[key]['TotalGain'] = 1 + (groupSummary[key]['TotalBonus']/100)
  return 0
  })
  const groupSummaryArray = Object.entries(groupSummary);
  return groupSummaryArray
}

//Token Sort Function for quest grouping.  
function GetSortOrderForQuesting(prop) {    
  return function(a, b) {    
      if (a.attributes.filter(i => i.attribute_type === prop)[0].attribute_value > b.attributes.filter(i => i.attribute_type === prop)[0].attribute_value) return -1;    
        if (a.attributes.filter(i => i.attribute_type === prop)[0].attribute_value < b.attributes.filter(i => i.attribute_type === prop)[0].attribute_value) return 1;   
          // if (a.tokenId > b.tokenId) return 1;
          //   if (a.tokenId < b.tokenId) return -1;
              else return 0   
                        }    
                      }
//Token Sort Function    
function GetSortOrder(prop) {    
  return function(a, b) {    
      // Check if the sort attribute exists. If not, return zzzUnassigned to push to bottom.
      let afound, bfound, aprop, bprop
      afound = (a.attributes.find(i => i.attribute_type === prop))
      if (afound) {aprop = a.attributes.filter(i => i.attribute_type === prop)[0].attribute_value} else {aprop = 'zzzUnassigned'} 
      
      bfound = (b.attributes.find(i => i.attribute_type === prop))
      if (bfound) {bprop = b.attributes.filter(i => i.attribute_type === prop)[0].attribute_value} else {bprop = 'zzzUnassigned'} 
      

      if (aprop > bprop) return 1;    
        if (aprop < bprop) return -1;    
          if (a.tokenId > b.tokenId) return 1;
            if (a.tokenId < b.tokenId) return -1;
              else return 0   
                        }    
                      } 
//Summary Sort Function 
function GetSummarySort(){
  return function(a, b) {
    if(a[1] > b[1]) return -1;
        if(a[1] < b[1]) return 1;
          if (a[0] > b[0]) return 1;
            if(a[0] < b[0]) return -1;
               else return 0
                        }
                      }

var foundTokens
if(tokenData.loadingdata === 0){
if(tokenData.tokendata[0]) {
  if(tokenData.tokendata[0].length){
    foundTokens = true
  } else {foundTokens = false}
} else {foundTokens = false}
}

if (foundTokens & props.collection === collectionCheck.collectioncheck) {  
      var grpAtt1 = []
      var grpAtt2 = []
      
      // Get values of summary group attributes. Return 'Unassigned' if the desired trait is not found.
      grpAtt1 =  tokenData.tokendata[0].map(t => 
        {
          const found = t.attributes.find(x => x.attribute_type === grp1);
          return found ? t.attributes.filter(x => x.attribute_type === grp1)[0].attribute_value : 'Unassigned'
        })

      grpAtt2 = tokenData.tokendata[0].map(t => 
        {
                                                const found = t.attributes.find(x => x.attribute_type === grp2);
                                                return found ? t.attributes.filter(x => x.attribute_type === grp2)[0].attribute_value : 'Unassigned'
        })

      const grpAtt1Summary = {}
      for (const grpAtt of grpAtt1) {
        grpAtt1Summary[grpAtt] = (grpAtt1Summary[grpAtt] || 0) + 1;
      }
      const grpAtt1Array = Object.entries(grpAtt1Summary);
      grpAtt1Array.sort(GetSummarySort())
     
      const grpAtt2Summary = {}
      for (const grpAtt of grpAtt2) {
        grpAtt2Summary[grpAtt] = (grpAtt2Summary[grpAtt] || 0) + 1;
      }
      const grpAtt2Array = Object.entries(grpAtt2Summary);
      grpAtt2Array.sort(GetSummarySort())

      //  Sort the tokens by selected sort option.
      tokenData.tokendata[0].sort(GetSortOrder(sort))

      //  If the collection is uunicorns do Questing Group calculations.
      var questingGroups
      var questGroupSummary
      if(props.collection === 'uunicorns'){
        questingGroups = GetQuestingGroups(tokenData.tokendata[0]);
        questGroupSummary = GetGroupSummary(questingGroups);
      }

      //  Return the markup.
      return (
       
          
          <div className="container-fluid ">
            <div className="row">
            <div className="col-md-11">
            {props.collectionTitle} owned by {props.account}
            <div className="row text-center token-wrapper">
            <div className="col-md-2 bg-token">
            <div className="token-name">{grp1} Summary</div>
              <table className="table-summary">
                <tbody>
                {grpAtt1Array.map((a) => {return ( <tr key={a[0]}> 
                                                                              <td className="att-title-sum"> <span> {a[0]}: </span> </td> 
                                                                              <td className="att-value-sum"> <span> {a[1]} </span> </td> 
                                                                              </tr>)}
                                                                              )} 
                </tbody>
              </table>
            </div>
            <div className="col-md-2 bg-token">
            <div className="token-name">{grp2} Summary</div>
              <table className="table-summary">
                <tbody>
                {grpAtt2Array.map((a) => {return ( <tr key={a[0]}> 
                                                                              <td className="att-title-sum"> <span> {a[0]}: </span> </td> 
                                                                              <td className="att-value-sum"> <span> {a[1]} </span> </td> 
                                                                              </tr>)}
                                                                              )} 
                </tbody>
              </table>
              </div>
            {props.collection === "uunicorns"? 
            <div className="col-md-4 bg-token">
            <div className="token-name">Questing Party Summary*</div>
              <table className="table-group-summary">
                <thead>
                  <tr>
                    <th></th>
                    <th>Clan Bonus</th>
                    <th>Rarity Bonus</th>
                    <th>Total Bonus</th>
                  </tr>
                </thead>
                <tbody>
                {questGroupSummary.map((a,key) => {return ( <tr key={key}> 
                                                                              <td className="att-title-sum"> <span> Party {key + 1} </span> </td> 
                                                                              <td className="att-value-sum"> <span> {a[1].ClanBonus}% </span> </td> 
                                                                              <td className="att-value-sum"> <span> {a[1].RarityBonus}% </span> </td> 
                                                                              <td className="att-value-sum"> <span> {a[1].TotalBonus}% </span> </td> 
                                                                              </tr>)}
                                                                              )} 
                </tbody>
              </table>
              </div>:<div></div>}
              </div>
            <div className="row text-center token-wrapper">
              
              {
              props.sortBy === "Questing Party"? 
              questingGroups.map((tokengroup, tgkey) => {
                return (  <div key={tgkey} className="row text-center group-wrapper">
                  {/* <div className="row text-center"> */}
                  <div className="col-md-4 group-title">
                  <span> Questing Party {tgkey + 1}</span>
                  </div>
                  {/* </div> */}
                  <div className="row text-center token-wrapper">
                  {tokengroup[1].map((token, key) => {
                  
                  return (
                          <div key={key} className="col-md-2 bg-token">
                                      <a className="token-name" target="_blank" href={"https://opensea.io/assets/" + props.contract + "/" + token.tokenId + "?ref=0xCf9160Cb6bc241Bdc1cd4Ba958c9b2114172F734"} rel="noopener noreferrer">
                                      <img src={ token.tokenImg } alt=""></img>
                                      </a>
                                      <div className="token-name">
                                        <a className="token-name" target="_blank" href={"https://opensea.io/assets/" + props.contract + "/" + token.tokenId + "?ref=0xCf9160Cb6bc241Bdc1cd4Ba958c9b2114172F734"} rel="noopener noreferrer">
                                            {token.tokenName}
                                        </a>
                                        {collectionDetails["rarityurl"] === "" ? "":
                                        <a target="_blank" href={collectionDetails["rarityurl"] + token.tokenId} rel="noopener noreferrer"> 
                                          <img className="rt" src="/rtools.png" alt="rt"></img> 
                                        </a>}
                                      </div>
                                      {(props.hideDetails) ? "":
                                      <table className="table-token">
                                        <tbody>
                                        {token.attributes.map((att, akey) => {return ( <tr key={akey}> 
                                                                                                      <td className="att-title"> <span> {att.attribute_type}: </span> </td> 
                                                                                                      <td className="att-value"> <span> {att.attribute_value} </span> </td> 
                                                                                                      </tr>)}
                                                                                                      )} 
                                        </tbody>
                                      </table>
                                }
                  </div>
                  )})}
                  </div>
                </div>  
                )
              })
              
              :
              tokenData.tokendata[0].map((token, key) => {
                return(
                  <div key={key} className="col-md-2 bg-token">
                    <OwnedLazyToken
                              key={key}
                              token={token}
                              props={props}
                              collectionDetails={collectionDetails}
                              // width={size.width}
                              // height={size.height}
                              src={token.TokenImage}
                            />
                    {/* <a className="token-name" target="_blank" href={"https://opensea.io/assets/" + props.contract + "/" + token.tokenId + "?ref=0xCf9160Cb6bc241Bdc1cd4Ba958c9b2114172F734"} rel="noopener noreferrer">
                      <img src={ token.tokenImg } alt="">
                            </img>
                    </a>
                    <div className="token-name">
                      <a className="token-name" target="_blank" href={"https://opensea.io/assets/" + props.contract + "/" + token.tokenId + "?ref=0xCf9160Cb6bc241Bdc1cd4Ba958c9b2114172F734"} rel="noopener noreferrer">
                          {token.tokenName}
                      </a>
                      {collectionDetails["rarityurl"] === "" ? "":
                          <a target="_blank" href={collectionDetails["rarityurl"] + token.tokenId} rel="noopener noreferrer"> 
                            <img className="rt" src="/rtools.png" alt="rt"></img> 
                          </a>}
                    </div>
                    {(props.hideDetails) ? "":
                    <table className="table-token">
                      <tbody>
                      {token.attributes.map((att, akey) => {return ( <tr key={akey}> 
                                                                                    <td className="att-title"> <span> {att.attribute_type}: </span> </td> 
                                                                                    <td className="att-value"> <span> {att.attribute_value} </span> </td> 
                                                                                    </tr>)}
                                                                                    )} 
                      </tbody>
                    </table>
              } */}
                  </div>
                )
              })}
              </div>
              </div>
              {/* <div className="col-md-1">
                <div className="col-md-12 text-center suggestions">
                    <div className="token-name">Suggested Additions</div>
                       <GetSuggested contract={props.contract} neededclan={neededClan}/>  
                </div>
              </div> */} 
              </div>
            </div>
        
      );
    } else {
        return(
          
            <div className="tile">
            <div className="col-md-11">
            {tokenData.loadingdata === 1 ?
                      
                      // <div class="col-12">
                  <div className="snippet" data-title=".dot-falling">
                    <div className="stage">
                    <p> Fetching tokens owned by the address. Please be patient, this may take a few minutes.</p>
                      <div className="dot-falling"></div>
                    </div>
                  </div>
                // </div>
            : tokenData.loadingdata === 2 ? <p>There was a problem loading data from the TokenEyes API. Please try back later.</p> 
            : <p>No Tokens found. Please enter an Address, select a Collection, and click 'Get Tokens' to retrieve a list of owned tokens.</p>
            }                 
            </div>
            </div>
        )
    }

}

export default GetTokens;