import React from 'react'
import { useInView } from 'react-intersection-observer'

const CollectionLazyToken = ({ token, props, collectionDetails}) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '-250px 0px',
  })

  return (
     <div
      ref={ref}
      data-inview={inView}
      // style={{
      //   position: 'relative',
      //   paddingBottom: `${(height / width) * 100}%`,
      // }}
     >
      {inView ? (
        <div className="fade-in">
            <a className="token-name" target="_blank" href={"https://opensea.io/assets/" + props.contract + "/" + token.tokenId + "?ref=0xCf9160Cb6bc241Bdc1cd4Ba958c9b2114172F734"} rel="noopener noreferrer">
                      <img src={ token.tokenImg } alt="">
                            </img>
                    </a>
                    <div className="token-name">
                      <a className="token-name" target="_blank" href={"https://opensea.io/assets/" + props.contract + "/" + token.tokenId + "?ref=0xCf9160Cb6bc241Bdc1cd4Ba958c9b2114172F734"} rel="noopener noreferrer">
                          {token.tokenName}
                      </a>
                      {collectionDetails["rarityurl"] === "" ? "":
                          <a target="_blank" href={collectionDetails["rarityurl"] + token.tokenId} rel="noopener noreferrer"> 
                            <img className="rt" src="/rtools.png" alt="rt"></img> 
                          </a>}
                    </div>
                    {(props.hideDetails) ? "":
                    <table className="table-token">
                      <tbody>
                      {token.attributes.map((att, akey) => {return ( <tr key={akey}> 
                                                                                    <td className="att-title"> <span> {att.attribute_type}: </span> </td> 
                                                                                    <td className="att-value"> <span> {att.attribute_value} </span> </td> 
                                                                                    </tr>)}
                                                                                    )} 
                      </tbody>
                    </table>
              }
      </div>
      ) : <div>
            <div className="animated-background">
            {/* <img className="rt" src="/favicon.ico" alt="rt"></img> */}
            </div>
            </div>
            }
     </div>
  )
}

export default CollectionLazyToken