import React, {useState, useEffect} from 'react';
import CollectionLazyToken from '../../functions/CollectionLazyToken.js';
import InfiniteScroll from 'react-infinite-scroll-component';
  
const InfiniteScrollTokens = ({filteredTokens, props, collectionDetails}) => {
  const [count, setCount] = useState({
    prev: 0,
    next: 10
  })
  const [hasMore, setHasMore] = useState(true);
  const [current, setCurrent] = useState([])
  // console.log(filteredTokens)
  
  useEffect(() => {
    setCount({prev: 0, next: 10})
    setCurrent(filteredTokens.slice(0,10))
  },[filteredTokens])
  
  // console.log(current)
  // console.log(count.prev, count.next)
  const getMoreData = () => {
    if (current.length === filteredTokens.length) {
      setHasMore(false);
      return;
    }
    setTimeout(() => {
      setCurrent(current.concat(filteredTokens.slice(count.prev + 10, count.next + 10)))
    }, 2000)
    setCount((prevState) => ({ prev: prevState.prev + 10, next: prevState.next + 10 }))
  }

    return (
        <InfiniteScroll
        dataLength={current.length}
        next={getMoreData}
        hasMore={hasMore}
        loader={<div className="dot-falling"></div>}
      >
      
      <div className="row text-center token-wrapper">             
        
        {current && current.map((token, key) => {
        // filteredTokens.map((token, key) => {
          return(
             <div key={key} className="col-md-2 bg-token">
              <CollectionLazyToken
                        key={token.Token}
                        token={token}
                        props={props}
                        collectionDetails={collectionDetails}
                        // width={size.width}
                        // height={size.height}
                        src={token.TokenImage}
                      />
          
             {/*  <div className="token-image-bg"> 
              <a className="token-image-bg" target="_blank" href={"https://opensea.io/assets/" + props.contract + "/" + token.Token + "?ref=0xCf9160Cb6bc241Bdc1cd4Ba958c9b2114172F734"} rel="noopener noreferrer">
                 <img src-data={ token.TokenImage } style={{ zIndex: 2}} alt="..."> </img> 
                      <LazyImage
                        //key={token.tokenId}
                        alt="..."
                        // width={size.width}
                        // height={size.height}
                        src={token.TokenImage}
                      />      
              </a>
              {token.LowestListing.toString() === "NaN" ? "": 
                      <div className="price">
                        <a className="token-name" target="_blank" href={"https://opensea.io/assets/" + props.contract + "/" + token.Token + "?ref=0xCf9160Cb6bc241Bdc1cd4Ba958c9b2114172F734"} rel="noopener noreferrer">
                      {token.LowestListing.toString()}
                      <img className="rt" src="/ethereum-eth-logo.png" alt="eth"></img> 
                      </a>
                      </div>
                   }
              </div>
              <div className="token-name">
                
                <a className="token-name" target="_blank" href={"https://opensea.io/assets/" + props.contract + "/" + token.Token + "?ref=0xCf9160Cb6bc241Bdc1cd4Ba958c9b2114172F734"} rel="noopener noreferrer">
                    {token.TokenName}
                </a>
                {collectionDetails["rarityurl"] === "" ? "":
                    <a target="_blank" href={collectionDetails["rarityurl"] + token.Token} rel="noopener noreferrer"> 
                      <img className="rt" src="/rtools.png" alt="rt"></img> 
                    </a>}
               
              </div>
              {(props.hideDetails) ? "":
              <table className="table-token">
                <tbody>
                {token.attributes.map((att, akey) => {return ( <tr key={akey}> 
                                                                              <td className="att-title"> <span> {att.attribute_name}: </span> </td> 
                                                                              <td className="att-value"> <span> {att.attribute_value} </span> </td> 
                                                                              </tr>)}
                                                                              )} 
                </tbody>
              </table> 
        }*/}
            </div>
          )
        })}
        
        </div>
        </InfiniteScroll>
    );
  }

  
export default InfiniteScrollTokens;