import React, { useState, useEffect } from 'react';
import GetTokens from '../GetTokens';
import Dropdown from '../Dropdown';
import { useMoralis } from "react-moralis";
import axios from 'axios';

function TokenTracker(props) {
  const {isAuthenticated, user} = useMoralis()
  const [accountField, setAccountField] = useState('')
  const [account, setAccount] = useState('')
  //const [i, seti] = useState(1)
  const [contract, setContract] = useState('0xc4a0b1e7aa137ada8b2f911a501638088dfdd508')
  const [collection, setCollection] = useState('uunicorns')
  const [collection_id, setCollection_ID] = useState('1')
  const [collectionTitle, setCollectionTitle] = useState('Select a Collection')
  const [hasIEnumberable, setHasIEnumberable] = useState(0)
  const [hideDetails, setHideDetails] = useState(false)
  // const teAddress = '0xCf9160Cb6bc241Bdc1cd4Ba958c9b2114172F734'
  const [options, setOptions] = useState({ collectionViewOptions: [], collectionGroups: [], loadingoptions: 1 });
  //var collectionViewOptions = []//require('../../lists/collections.json')
  //const collectionGroups = require('../../lists/collectiongroups.json')
  const [sortBy, setSortBy] = useState('Class')
  const [arrayOfData, setArrayOfData] = useState([
                                                  {
                                                    id: 0,
                                                    title: 'Sort By',
                                                    selected: false,
                                                    key: 'arrayOfData'   
                                                  }
                                                ])


  // const toggleSelected = (id, key) => {
  //   let temp = JSON.parse(JSON.stringify(this.state[key]))
  //   temp[id].selected = !temp[id].selected
  //   this.setState({
  //     [key]: temp
  //   })
  // }

  useEffect(() => {
    const fetchData = async () => { 
      setOptions({loadingoptions: 1})   
            try {
                const collectionViewOptionsReturn = await Promise.all([axios("https://api.tokeneyes.io/api/collections_tokentracking")]) 

                const collectionGroupsReturn = await Promise.all([axios("https://api.tokeneyes.io/api/collectionGroups")]);

                setOptions({collectionViewOptions: collectionViewOptionsReturn[0].data[0], collectionGroups: collectionGroupsReturn[0].data[0], loadingoptions: 0})
            } catch (error) {
              console.log(error)
              setOptions({loadingoptions: 2})
              }
    };
    fetchData();
},[]);


  const resetThenSet = (id, key) => {
    options.collectionViewOptions.forEach(item => item.selected = false);
    options.collectionViewOptions[id].selected = true;
    //seti(id)
    setContract(options.collectionViewOptions[id].contract)
    setCollection(options.collectionViewOptions[id].collectionName)
    setCollection_ID(options.collectionViewOptions[id].collectionid)
    setCollectionTitle(options.collectionViewOptions[id].title)
    setHasIEnumberable(options.collectionViewOptions[id].hasienumerable)
    setArrayOfData(options.collectionGroups.filter(grp => {return grp.Collection_Id === options.collectionViewOptions[id].collectionid})[0].CollectionGroups)
    setSortBy(arrayOfData[0])
  }

  const sortResetThenSet = (id, key) => {
    arrayOfData.forEach(item => item.selected = false);
    arrayOfData[id].selected = true;
    //seti(id)
    setSortBy(arrayOfData[id].title)
  }

  const handleAccountChange = (event) => {
    // Update the state object
    event.preventDefault()
    setAccountField(event.target.value)
  }
  
 function getTokensClick() {
    // Update the state object
    if (accountField !== '') {
      setAccount(accountField)
    } else {
      if (isAuthenticated) {
        setAccount(user.get("ethAddress"))
      }
    }

  }

  const onClick = () => {
    setHideDetails(!hideDetails);
  };

  
    return (   
          <div className="container-fluid mt-5">
            <div className="row">
              <div className="col-md-2 filter-bar">
                  Address
                  <input className ="address-input" type="text" placeholder={isAuthenticated ? user.get("ethAddress") : 'Enter an Address'} onChange={handleAccountChange} />
                  
                  Collection
                  <Dropdown
                      title={collectionTitle}
                      list={options.collectionViewOptions}
                      resetThenSet={resetThenSet}
                  />
                  Sort By
                  <Dropdown
                    title="Sort By"
                    list={arrayOfData}
                    resetThenSet={sortResetThenSet}
                  />
                <div>
                  <input
                    className=""
                    type="checkbox"
                    value={"this.state.hideDetails"}
                    checked={hideDetails}
                    onChange={onClick}
                  /> Hide Details
                </div>
                <button className="button-te" onClick={(event) => {
                  event.preventDefault()
                  getTokensClick();
                }} id="login"> Get Tokens </button> 
              
              </div>
              <div className="col-md-10">
                <div className="row">
                                
                  <div className="col-md-12 text-center">
                  
                    <GetTokens account={account} collectionTitle={collectionTitle} contract={contract} collectionID={collection_id} 
                            collection={collection} hasIEnumberable={hasIEnumberable} sortBy={sortBy} hideDetails={hideDetails}
                            collections={options.collectionViewOptions} collectionGroups={options.collectionGroups} loadingoptions={options.loadingoptions}/>
                  </div>
                </div>
              </div>
              
            </div> 
            <div className="row">
                                
                  <div className="col-md-12 text-center">
            <p className="disclaimer">Disclaimer: OpenSea links are tagged with a referral link to the TokenEyesio wallet address. OpenSea is not currently supporting this referral program. Please consider donating to support TokenEyes. Thank you for your support!
        <br/> *Questing Parties are experimental and best guesses at grouping Unicorns. Please review and do your own research before making any purchase or questing decisions.</p>
        </div>
              
            </div>
       
          </div>
          
        
    );

}

export default TokenTracker;